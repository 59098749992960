import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { generateSchoolSessions } from "../utils/schoolYearUtils";
import { userRequest } from "../utils/requestMethods";
import { useStateContext } from "../contexts/ContextProvider";

const TermManagementModal = ({ closeModal, school }) => {
  const { refreshSchool } = useStateContext();
  // Local state for term details. We no longer store _id; we use the term number for matching.
  const [terms, setTerms] = useState([
    { term: "1", label: "1st Term", startDate: "", endDate: "" },
    { term: "2", label: "2nd Term", startDate: "", endDate: "" },
    { term: "3", label: "3rd Term", startDate: "", endDate: "" },
  ]);

  const schoolSessions = generateSchoolSessions(2022, 20);
  const [term, setTerm] = useState("");
  const [session, setSession] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState({ termIndex: null, sessionTerm: false });

  // Pre-fill local state with existing terms from school.settings.terms based on term number
  useEffect(() => {
    if (school?.settings?.terms && school.settings.terms.length > 0) {
      const updatedTerms = terms.map((localTerm) => {
        const existing = school.settings.terms.find(
          (t) => Number(t.term) === Number(localTerm.term)
        );
        if (existing) {
          return {
            ...localTerm,
            startDate: existing.start_date.split("T")[0], // Format date to YYYY-MM-DD
            endDate: existing.end_date.split("T")[0],
          };
        }
        return localTerm;
      });
      setTerms(updatedTerms);
      setTerm(String(school?.settings?.current_term));
      setSession(school?.settings?.current_session);
    }
  }, [school?.settings?.terms]);

  // Check if a term record exists by term number
  const termExists = (termNumber) => {
    return school?.settings?.terms?.some(
      (t) => Number(t.term) === Number(termNumber)
    );
  };

  // Function to handle saving/updating a term
  const handleSave = async (index) => {
    const { term, startDate, endDate, label } = terms[index];
    if (!startDate || !endDate) {
      setError("Please fill in both start and end dates.");
      setTimeout(() => setError(""), 5000);
      return;
    }
    setLoading((prev) => ({ ...prev, termIndex: index }));
    try {
      if (termExists(term)) {
        // If a term record already exists (by term number), update it using PATCH
        await userRequest.patch(`/schools/${school._id}/settings/term`, {
          term: {
            term,
            start_date: startDate,
            end_date: endDate,
          },
        });
        setSuccess(`${label} details updated successfully.`);
      } else {
        // Otherwise, create a new term record with POST
        await userRequest.post(`/schools/${school._id}/settings/term`, {
          term,
          start_date: startDate,
          end_date: endDate,
        });
        setSuccess(`${label} details saved successfully.`);
      }
      refreshSchool();
      setTimeout(() => setSuccess(""), 3000);
    } catch (error) {
      console.error(error);
      setError("Failed to save term details. Please try again.");
      setTimeout(() => setError(""), 5000);
    } finally {
      setLoading((prev) => ({ ...prev, termIndex: null }));
    }
  };

  const handleTermChange = (index, key, value) => {
    const updatedTerms = [...terms];
    updatedTerms[index][key] = value;
    setTerms(updatedTerms);
  };

  // For session-term submission (kept as POST)
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!term || !session) {
      setError("Please select a term and session.");
      setTimeout(() => setError(""), 5000);
      return;
    }
    setLoading((prev) => ({ ...prev, sessionTerm: true }));
    try {
      await userRequest.post(`/schools/${school._id}/settings/session-term`, {
        term: parseInt(term, 10),
        session,
      });
      setSuccess("School term and session added.");
      refreshSchool();
      setTimeout(() => {
        setSuccess("");
        closeModal();
      }, 2000);
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again.");
      setTimeout(() => setError(""), 5000);
    } finally {
      setLoading((prev) => ({ ...prev, sessionTerm: false }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-full max-w-2xl p-6 overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Term Management</h2>
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-gray-900 hover:bg-gray-200 p-2 w-8 h-8 rounded-lg"
          >
            <FaTimes />
          </button>
        </div>
        <p className="text-sm text-gray-500 mt-2">
          Set up your academic terms start and end dates.
        </p>
        {error && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
          </div>
        )}
        {success && (
          <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
            <span className="font-medium">Success!</span> {success}
          </div>
        )}

        {/* Term Details */}
        <div className="mt-4">
          {terms.map((termData, index) => (
            <div key={index} className="border border-gray-200 rounded-lg p-4 mb-4 flex flex-col space-y-2">
              <h3 className="text-sm font-bold">{termData.label}</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="relative">
                  <input
                    type="date"
                    value={termData.startDate}
                    onChange={(e) => handleTermChange(index, "startDate", e.target.value)}
                    className="block w-full border border-gray-300 rounded-lg p-2 text-sm"
                  />
                </div>
                <div className="relative">
                  <input
                    type="date"
                    value={termData.endDate}
                    onChange={(e) => handleTermChange(index, "endDate", e.target.value)}
                    className="block w-full border border-gray-300 rounded-lg p-2 text-sm"
                  />
                </div>
              </div>
              <button
                onClick={() => handleSave(index)}
                className="bg-indigo-50 text-indigo-500 font-semibold text-sm rounded-lg py-2 hover:bg-indigo-100 transition"
                disabled={loading.termIndex === index}
              >
                {loading.termIndex === index ? "Saving..." : "Save"}
              </button>
            </div>
          ))}
        </div>

        {/* Session & Term Section */}
        <div className="border border-gray-200 rounded-lg p-4 mt-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="term" className="block mb-2 text-sm font-medium">
                Term
              </label>
              <select
                id="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                className="block w-full border border-gray-300 rounded-lg p-2 text-sm"
              >
                <option value="">Select Term</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            <div>
              <label htmlFor="session" className="block mb-2 text-sm font-medium">
                Session
              </label>
              <select
                id="session"
                value={session}
                onChange={(e) => setSession(e.target.value)}
                className="block w-full border border-gray-300 rounded-lg p-2 text-sm"
              >
                <option value="">Select Session</option>
                {schoolSessions.map((session) => (
                  <option key={session.value} value={session.value}>
                    {session.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={submitHandler}
            className="mt-4 w-full bg-indigo-600 text-white py-2 rounded-lg text-sm font-semibold hover:bg-indigo-700 transition"
            disabled={loading.sessionTerm}
          >
            {loading.sessionTerm ? "Saving..." : "Save Session & Term"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermManagementModal;
