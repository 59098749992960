import React, { useState, useEffect } from 'react';
import { userRequest } from '../utils/requestMethods';
import { Header, TransactionTable } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const AllTransactions = () => {
  useKYCStatusRedirect("/all-payments");
  const { user, school, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = user?._id;
  const schoolId = school?._id;

  useEffect(() => {
    const fetchTransactions = async () => {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`transactions/${userId}/${schoolId}?limit=1000&sort_by=date&order=des`);
          //console.log(data);
          setTransactions(data.transactions);
          setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    fetchTransactions();
  }, [userId, schoolId]);

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const start = new Date(startDate).toISOString().slice(0, 10);
    const end = new Date(endDate).toISOString().slice(0, 10);
    try {
      const { data } = await userRequest.get(`transactions/${userId}/${schoolId}?limit=200&sort_by=date&order=des&from=${start}&to=${end}&type=school_fee`);
      //console.log(data);
      setTransactions(data.transactions);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-5 mt-24 p-6 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen md:overflow-auto">
        <button type="button" onClick={() => setClicked(!clicked)} style={{ backgroundColor: currentColor }} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
            Filters
        </button>
        <Header category="Payments" title="All Payments" />
        {clicked ? (
          <div className="absolute right-4 z-50 bg-white border-2 shadow-lg rounded-lg p-4 w-64" style={{ top: "12rem" }}>
            <div className="md:grid gap-6 mb-6 grid-cols-1">
              <div>
                <label htmlFor="startDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start Date</label>
                <input type="date" id="startDate" onChange={(e) => setStartDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div>
                <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End Date</label>
                <input type="date" id="endDate" onChange={(e) => setEndDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div className="mt-2">
                <button type="submit" disabled={loading} onClick={submitHandler} className="w-full py-1 px-8 border border-transparent shadow-sm font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Search</button>
              </div>
            </div>
          </div>
        ) : null }
        <TransactionTable transactions={transactions} loading={loading} />
    </div>
  );
};
export default AllTransactions;
