import React, { useEffect, useState } from "react";
import { userRequest } from "../../utils/requestMethods";

const EditSubject = ({ closeModal, onSubjectUpdated, currentsubject, schoolId, classId }) => {
  const [subjectName, setSubjectName] = useState("");
  const [subjectDescription, setSubjectDescription] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
      if (currentsubject) {
        console.log(currentsubject);
        setSubjectName(currentsubject?.subject_name);
        setSubjectDescription(currentsubject?.subject_description);
      }
    }, [currentsubject]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!subjectName || !subjectDescription) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.put(`/subjects/${schoolId}/${classId}/${currentsubject._id}`,
        { 
          subject_name:subjectName,
          subject_description: subjectDescription,
        });
      //console.log(data);
      onSubjectUpdated();
      closeModal();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setSubjectName("");
        setSubjectDescription("");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full max-w-md p-6">
        {/* Close Button */}
        <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Edit Subject Details</h2>
            <button type="button" onClick={closeModal} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
        </div>

        {/* Main Screen */}
        <div className="mt-4">
          <p className="text-sm text-gray-500 mt-2">
            Enter subject details
          </p>
          <div className="mt-3">
            {error &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
                <span className="font-medium">Error!</span> {error}
            </div>}
            {success &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
              <span className="font-medium">Success!</span> New Subject Added
            </div>}
            <form onSubmit={submitHandler} class="space-y-4">
              <div>
                <label htmlFor="subjectName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                <input type="text" id="subjectName" onChange={(e) => setSubjectName(e.target.value)} value={subjectName} placeholder="Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
              </div>
              <div>
                <label htmlFor="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                <textarea type="text" id="subject_description" onChange={(e) => setSubjectDescription(e.target.value)} value={subjectDescription} placeholder="Description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required></textarea>
              </div>
              <div className="flex justify-between items-center">
                <button type="button" onClick={closeModal} className="text-gray-600 bg-white border border-gray-300 font-semibold rounded-full text-sm px-5 py-2 mr-3">Close</button>
                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                    {loading ? 'Updating...' : 'Update Subject'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubject;
