import { Button } from "../../components/adminDashboard/ResultsComponents"
import { FiEdit3 } from "react-icons/fi"
import { useState, useEffect } from "react"
import { userRequest } from "../../utils/requestMethods";
import { useStateContext } from "../../contexts/ContextProvider";

export default function Summary({ history }){
  const { school } = useStateContext();
  console.log(school);
  const [saveModal, setSaveModal] = useState(false);
  const [summary, setSummary] = useState({
    assessment: [],
    customization: {},
    grading: [],
    rat: {}
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const loadSummary = () => {
    const loadedSummary = JSON.parse(localStorage.getItem("summary"))
    if (loadedSummary){
      setSummary(loadedSummary)
    }
    console.log(JSON.parse(localStorage.getItem("summary")))
  }

  useEffect(() => {
    loadSummary()
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Extract the assessment with name === 0
    const maxCA = summary.assessment.find((ca) => ca.name === 0)?.value || 0;
  
    // Filter out the assessments with name !== 0 and map them to individual CA scores
    const cas = summary.assessment
      .filter((ca) => ca.name !== 0)
      .map((ca) => ({
        max_score: ca.value,
      }));
  
    // Calculate the max score for the exam
    const totalCA = cas.reduce((total, ca) => total + ca.max_score, 0);
    const maxExamScore = maxCA - totalCA;
  
    // Ensure exam max score is valid
    if (maxExamScore < 0) {
      setError("Total CA scores exceed the maximum allowed CA score.");
      setTimeout(() => setError(""), 5000);
      return;
    }
  
    const requestBody = {
      //current_term: school.settings.current_term,
      //current_session: school.settings.current_session,
      /*terms: school.settings.terms.map((term) => ({
        term: term.term,
        start_date: term.start_date,
        end_date: term.end_date,
      })), // Removed the outer array wrapping around the map*/
      assessment: {
        ca: {
          cas,
          max_score: maxCA,
        },
        exam: {
          max_score: 100 - maxCA,
        },
      },
      grading: {
        grades: summary.grading.map((grade) => ({
          grade: grade.name,
          min_score: grade.value[0],
          max_score: grade.value[1],
        })),
      },
      remark: {
        teacher_remark: summary.remarksAndMore.comments || "Well done",
        overall_comment: summary.remarksAndMore.remarks || "Well done",
        skills: summary.remarksAndMore.skillsList || [],
        ratings: summary.remarksAndMore.abilitiesList || [],
        //remarks: ["Well done"],
        remarks: summary.remarksAndMore.remarksList || ["Well done"],
      },
      result: {
        teacher_preview: summary.customization.preview,
        admin_approval: summary.customization.review,
        parent_payment_threshold: 0, // Adjust as per actual requirements
      },
    };
    
  
    console.log(requestBody);
  
    try {
      await userRequest.post(`/schools/${school._id}/settings`, requestBody);
      setSuccess("Settings saved successfully!");
      setSaveModal(true);
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data ? error?.response?.data : "Something went wrong. Please try again.");
      setTimeout(() => setError(""), 5000);
    } finally {
      setLoading(false);
    }
  };
  
  
  return(
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <div className="flex flex-wrap gap-4 justify-between items-center">
        <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 dark:text-gray-200">
          Result Setup Summary
        </h1>
        <Button onClick={submitHandler}>
          {loading ? "Saving..." : "Save and apply"}
        </Button>
      </div>
      {error && (
        <div className="p-4 my-4 text-sm text-white rounded-lg bg-red-500" role="alert">
          <span className="font-medium">Error!</span> {error}
        </div>
      )}
      {success && (
        <div className="p-4 my-4 text-sm text-white rounded-lg bg-green-500" role="alert">
          <span className="font-medium">Success!</span> {success}
        </div>
      )}
      <div className="mt-10 pt-10 border-t flex flex-wrap justify-between items-center gap-2">
        <div className="w-full max-w-2xl">
          <div className="flex justify-between gap-2 items-center">
            <p>Continous Assessment</p>
            <button className="text-red-500 hover:text-red-300 flex gap-2 items-center">
              <FiEdit3/> Edit
            </button>
          </div>

          { summary ? 
          <div className="bg-[#EEEEFC] mt-6 py-4 px-6 flex justify-between gap-3 rounded-xl">
            <div className="pr-4 border-r max-w-[150px] w-full">
              <p>Total Weight</p>
              <p className="font-semibold">
                {summary.assessment.length > 0 ? summary.assessment[0].value : 0}
              </p>
            </div>
            {
              
              summary.assessment.map(ca => (
                ca.name !== 0 && 
                <div key={ca.name} className="max-w-[150px] w-full">
                  <p className="text-[#6A69C4]">CA {ca.name}</p>
                  <p className="font-semibold">{ca.value}</p>
                </div>
              ))
            }
          </div> : <></>
          }
        </div>

        <div className="max-w-[200px] w-full">
          <div className="flex justify-between gap-2 items-center">
            <p>Exam Grade</p>
          </div>

          <div className="bg-[#EEEEFC] mt-6 py-4 px-6 flex gap-3 rounded-xl">
            <div className="max-w-[150px] w-full">
              <p>Total Weight</p>
              <p className="font-semibold">
              {summary.assessment.length > 0 ? 100 - summary.assessment[0].value : 0}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 pt-10 border-t flex flex-wrap justify-between items-center gap-2">
        <div className="w-full">
          <div className="flex justify-between gap-2 items-center">
            <p>Grading System</p>
            <button className="text-red-500 hover:text-red-300 flex gap-2 items-center">
              <FiEdit3/> Edit
            </button>
          </div>

          <div className="bg-[#EEEEFC] mt-6 py-4 px-6 flex justify-between gap-3 rounded-xl">
            {summary.grading.map( grade =>
            <div className="max-w-[150px] w-full">
              <p className="text-[#6A69C4]">{grade.name}</p>
              <p className="font-semibold">{`${grade.value[0]} - ${grade.value[1]}`}</p>
            </div>
            )
            }
          </div>
        </div>
      </div>

      <div className="mt-10 pt-10 border-t">
        <div className="flex justify-between gap-2 items-center">
          <p>General Customization</p>
          <button className="text-red-500 hover:text-red-300 flex gap-2 items-center">
            <FiEdit3/> Edit
          </button>
        </div>

        <div>
        <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Enable preview option for teachers</p>
              <p>{summary.customization.preview ? "Yes" : "No"}</p>
            </div>

            <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Admin must review all results</p>
              <p>{summary.customization.review ? "Yes" : "No"}</p>
            </div>

            <div className="flex justify-between gap-2 my-10">
              <p className="font-semibold">Teachers should customize result format (e.g CA weight adjustment)</p>
              <p>{summary.customization.customize ? "Yes" : "No"}</p>
            </div>
        </div>
      </div>

      {
        saveModal &&
        <div className="fixed top-0 left-0 w-full h-screen bg-[#34405480] backdrop-blur-sm flex items-center justify-center p-3" onClick={() => setSaveModal(false)}>
          <div className="bg-white max-w-md w-full rounded-3xl flex flex-col justify-center items-center gap-4 p-8">
            <img alt="check" src="/CheckCircle.svg" width={100}/>
            <p className="text-lg font-semibold my-4">Setup successful</p>
            <Button onClick={ () => history.push("/result-management")}>Proceed</Button>
          </div>
        </div>
      }
    </div>
  )
}