import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatter, userRequest } from '../utils/requestMethods';
import { displaySchoolTerm } from '../utils/schoolYearUtils';
import { customFormatter } from '../utils/currencyUtils';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { StatementModal } from '../components';
import * as Icons from '../data/home';

const Home = () => {
  //useKYCStatusRedirect("/home");
  const { user, school } = useStateContext();
  //console.log(school);
  const schoolId = school?._id;
  const schoolTerms = school?.settings?.terms || [];
  const schoolTerm = school?.settings?.current_term;
  const schoolSession = school?.settings?.current_session;
  const [balance, setBalance] = useState({});
  const [payout, setPayout] = useState({});
  const [paymentSummary, setPaymentSummary] = useState();
  //const [account, setAccount] = useState({});
  //console.log(school);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const {data} = await userRequest.get(`schools/${schoolId}/account-balance`);
        //console.log(data.data);
        setBalance(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBalance();
  }, [schoolId]);

  useEffect(() => {
    const fetchPayout = async () => {
      try {
        const {data} = await userRequest.get(`/transactions/school/${schoolId}/payout-summary`);
        //console.log(data);
        setPayout(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPayout();
  }, [schoolId]);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const {data} = await userRequest.get(`schools/${schoolId}/payment-summary?term=${school.term}&session=${school.session}`);
        //console.log(data);
        setPaymentSummary(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
  }, [schoolId, school?.term, school?.session]);
  
  return (
    <div className="mt-10">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center relative px-4 md:px-10 pt-8 space-y-4 md:space-y-0">
        <div>
          <h1 className="text-lg md:text-2xl font-extrabold mb-2">Welcome Back, {user?.firstname}</h1>
        </div>
        {schoolTerms.length === 0 ? (
          <p className="text-xs md:text-sm font-semibold text-red-500">
            Kindly add your school terms
          </p>
        ) : (
          <p className="text-xs md:text-sm font-semibold md:text-left">
            {displaySchoolTerm(schoolTerm)} - {schoolSession}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 md:m-2 mt-5 p-2 md:p-5">
        <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-48 rounded-3xl p-8 pt-9 m-3">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-400 text-sm">NGN Balance</p>
              <p className="text-2xl font-semibold">{customFormatter(balance?.availableBalance)}</p>
            </div>
            <button type="button" className="bg-[#3371E8] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
              <img src={Icons.wallet} alt="Wallet Icon" />
            </button>
          </div>
          <div className="my-5">
            <Link to="/withdraw">
              <button type="button" className="w-full text-indigo-700 hover:text-white border border-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">Withdraw Balance</button>
            </Link>
          </div>
        </div>

        <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-48 rounded-3xl p-8 pt-9 m-3">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-400 text-sm">Balance Payouts</p>
              <p className="text-2xl font-semibold">{customFormatter(payout?.totalPayout)}</p>
            </div>
            <button type="button" className="bg-[#41D3BD] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
              <img src={Icons.payout} alt="Payout Icon" />
            </button>
          </div>
          <div className="my-5">
            <Link to="/withdraw">
              <button type="button" className="w-full text-indigo-700 hover:text-white border border-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">View History</button>
            </Link>
          </div>
        </div>

        <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg h-48 rounded-3xl p-8 pt-9 m-3">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-400 text-sm">Cashflow</p>
              <p className="text-2xl font-semibold">{customFormatter(0)}</p>
            </div>
            <button type="button" className="bg-[#E26E6A] text-white text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-xl py-4 px-4">
              <img src={Icons.transfer} alt="Cashflow Icon" />
            </button>
          </div>
          <div className="my-5">
            <Link to="/financing/cashflow">
              <button type="button" className="w-full text-indigo-700 hover:text-white border border-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">Get Cashflow</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="grid m-2 md:px-3 items-center">
        <div className="p-2 m-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-center items-center">
            <Link to="/withdraw">
              <div className="flex flex-col items-center justify-center bg-[#F5F9FF] border-2 border-gray-200 hover:bg-[#3371E80F] rounded-3xl p-6 h-44 transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                <img src={Icons.withdrawal} alt="Withdraw Icon" />
                <span className="text-[#3371E8] text-sm font-medium">Withdraw</span>
              </div>
            </Link>
            <Link to="/expenses">
              <div className="flex flex-col items-center justify-center bg-[#F5F9FF] border-2 border-gray-200 hover:bg-[#3371E80F] rounded-3xl p-6 h-44 transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                <img src={Icons.send} alt="Transfer Icon" />
                <span className="text-[#296BF2] text-sm font-medium">Transfer</span>
              </div>
            </Link>
            <div onClick={toggleModal} className="flex flex-col items-center justify-center bg-[#F5F9FF] border-2 border-gray-200 hover:bg-[#3371E80F] rounded-3xl p-6 h-44 transition-transform duration-300 transform hover:scale-105 cursor-pointer">
              <img src={Icons.statement} alt="Statement Icon" />
              <span className="text-[#296BF2] text-sm font-medium">Statement</span>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#F5F9FF] border-2 border-gray-200 hover:bg-[#3371E80F] rounded-3xl p-6 h-44 transition-transform duration-300 transform hover:scale-105 cursor-pointer">
              <img src={Icons.bills} alt="Bills Icon" />
              <span className="text-[#296BF2] text-sm font-medium">Pay Bills (coming soon)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 mb-2 m-2 p-2 md:p-5">
        <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-lg">Account Details</p>
          </div>
          <div className="mt-4">
            <div className="flex justify-between mt-4 w-full">
              <div className="flex gap-4 mb-2">
                <button type="button" className="text-red-700 bg-red-100 text-2xl hover:drop-shadow-xl text-white rounded-xl py-3 px-5">
                  <img src={Icons.account} alt="Wallet Icon" />
                </button>
                <div>
                  <p className="text-md font-semibold">{school?.account_number}</p>
                  <p className="text-sm text-gray-400 font-semibold">9 Payment Service Bank</p>
                  <p className="text-xs text-gray-400 font-semibold">{school?.account_name}</p>
                </div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="mt-4">
              <p className="mt-2 text-sm text-gray-500">
                This account is an NDIC issured deposit account, that holds your main balance from all fee payments on Acceede. You can transfer from any bank account to this account instantly with the account number above. You can also withdraw from this account using the withdraw feature.
              </p>
            </div>
            <img src={Icons.ndicLogo} alt="Wallet Icon" />
          </div>
        </div>

        <div className="bg-white border-2 border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-lg">Transaction Summary</p>
          </div>
          <div className="gap-4 border-color mt-6">
            <div className="max-w-screen-xl w-full">
              <div className="grid rounded-lg shadow-sm md:grid-cols-2 bg-white">
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-green-700 mr-2 bg-green-100 focus:ring-4 focus:outline-none font-medium rounded-xl text-2xl py-4 px-4 text-center inline-flex items-center me-2">
                      <img src={Icons.decrease} alt="Chart Icon" />
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <p className="text-sm text-gray-500 dark:text-gray-400">Expected Income</p>
                      <p className="text-xl">{formatter.format(paymentSummary?.totalAmount || 0)}</p>
                    </div>
                  </figcaption>
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-green-700 mr-2 bg-green-100 focus:ring-4 focus:outline-none font-medium rounded-xl text-2xl py-4 px-4 text-center inline-flex items-center me-2">
                      <img src={Icons.list} alt="Chart Icon" />
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <p className="text-sm text-gray-500 dark:text-gray-400 ">Total Paid</p>
                      <p className="text-xl">{formatter.format(paymentSummary?.totalAmountPaid || 0)}</p>
                    </div>
                  </figcaption>
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-red-700 mr-2 bg-red-100 focus:ring-4 focus:outline-none font-medium rounded-xl text-2xl py-4 px-4 text-center inline-flex items-center me-2">
                      <img src={Icons.debt} alt="Chart Icon" />
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <p className="text-sm text-gray-500 dark:text-gray-400 ">Current Debt</p>
                      <p className="text-xl">{formatter.format(paymentSummary?.totalOutstandingAmount || 0)}</p>
                    </div>
                  </figcaption>  
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-red-700 mr-2 bg-red-100 focus:ring-4 focus:outline-none font-medium rounded-xl text-2xl py-4 px-4 text-center inline-flex items-center me-2">
                      <img src={Icons.discount} alt="Chart Icon" />
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <p className="text-sm text-gray-500 dark:text-gray-400 ">Discount</p>
                      <p className="text-xl">{formatter.format(paymentSummary?.discountAmount || 0)}</p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && 
        <StatementModal
        closeModal={toggleModal}
        school={school}
      />}
    </div>
  );
};

export default Home;
