import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { userRequest } from "../../../utils/requestMethods";
import { Header, CustomPagination, SkeletonTable, AlertNotification, BackButton } from "../../../components";
import { useStateContext } from "../../../contexts/ContextProvider";
import { MdSearch } from "react-icons/md";
import * as Icons from "../../../data/teacher";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
      fontWeight: "bold",
    },
  },
};

const StudentAttendance = ({ history }) => {
  const { school } = useStateContext();
  const location = useLocation();
  const classId = location.pathname.split('/')[4];
  const schoolId = school?._id;
  const schoolTerm = school?.settings?.current_term;
  const schoolSession = school?.settings?.current_session;
  const currentTermData = school?.settings?.terms.find(term => term.term === schoolTerm);
  const startDate = currentTermData?.start_date;
  const endDate = currentTermData?.end_date;

  const [filterText, setFilterText] = useState('');
  const [students, setStudents] = useState([]);
  // attendance state keyed by student _id; values: 1 for present, 2 for absent, null (or 0 when saving) for pending
  const [attendance, setAttendance] = useState({});
  // This state holds the raw attendance records returned from the fetchAttendance API
  const [studentAttendance, setStudentAttendance] = useState([]);
  // Selected date in YYYY-MM-DD format (default is today)
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Fetch students for a specific class
  const fetchStudents = async (classId) => {
    if (classId) {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`children/class/${classId}?limit=200`);
        setStudents(data.children);
        // Initialize attendance state when students are fetched
        initializeAttendance(data.children);
      } catch (error) {
        console.error("Error fetching students:", error);
        setError("Error fetching students");
      } finally {
        setLoading(false);
      }
    }
  };

  // Fully initialize (or reset) the attendance state for each student
  const initializeAttendance = (studentsList) => {
    const newAttendance = {};
    studentsList.forEach((student) => {
      newAttendance[student._id] = null; // Pending attendance
    });
    setAttendance(newAttendance);
  };

  // Fetch attendance for the selected date
  const fetchAttendance = async () => {
    if (!classId || !schoolId) return;
    setLoading(true);
    try {
      // Pass the date as a parameter (and from/to if needed)
      const { data } = await userRequest.get(
        `/attendance/class/${classId}?school_id=${schoolId}&date=${date}&term=${schoolTerm}&session=${schoolSession}&from=${date}&to=${date}&limit=50`
      );
      // Assume backend returns an array of attendance records in data.students
      if (data?.students) {
        setStudentAttendance(data.students);
      } else {
        setStudentAttendance([]);
      }
    } catch (error) {
      console.error("Error fetching attendance:", error);
      setError("Error fetching attendance");
    } finally {
      setLoading(false);
    }
  };

  // Call fetchStudents when the component mounts or when classId changes
  useEffect(() => {
    if (classId) fetchStudents(classId);
  }, [classId]);

  // Whenever the selected date changes (or the students list changes), reset the attendance state
  useEffect(() => {
    if (students.length > 0) {
      initializeAttendance(students);
    }
  }, [date, students]);

  // Call fetchAttendance when schoolId, classId, date, term, or session changes
  useEffect(() => {
    fetchAttendance();
  }, [schoolId, classId, date, schoolTerm, schoolSession]);

  // Merge fetched attendance into the attendance state based on student id
  useEffect(() => {
    if (studentAttendance && studentAttendance.length > 0) {
      setAttendance((prevAttendance) => {
        const updatedAttendance = { ...prevAttendance };
        studentAttendance.forEach((item) => {
          if (item.attendance && item.attendance.length > 0) {
            const records = item.attendance[0].records;
            const status = (records && records.length > 0) ? records[0].status : 0;
            updatedAttendance[item.student.id] = status;
          }
        });
        return updatedAttendance;
      });
    }
  }, [studentAttendance]);

  // Handle changing the attendance (present/absent) for a student
  const handleAttendanceChange = (childId, status) => {
    setAttendance((prev) => ({ ...prev, [childId]: status }));
  };

  // Save or update attendance to the backend
  const saveAttendance = async () => {
    setSaving(true);
    // Build the payload from the current attendance state
    const attendanceData = Object.entries(attendance).map(([childId, status]) => ({
      childId,
      status: status === null ? 0 : status, // Represent pending attendance as 0
    }));

    const payload = {
      attendance: attendanceData,
      classId,
      schoolId,
      date,
      reason: "Daily attendance",
      term: schoolTerm,
      session: schoolSession,
    };

    try {
      // If there is an existing attendance record for this date, update it with PUT.
      // Otherwise, create a new record with POST.
      if (studentAttendance && studentAttendance.length > 0) {
        await userRequest.put("/attendance/edit-multiple-student-attendance", payload);
        setSuccess("Attendance updated successfully!");
      } else {
        await userRequest.post("/attendance/mark-multiple-student-attendance", payload);
        setSuccess("Attendance saved successfully!");
      }
      setTimeout(() => setSuccess(""), 5000);
    } catch (error) {
      console.error("Error saving/updating attendance:", error);
      setError("Error saving/updating attendance");
      setTimeout(() => setError(""), 5000);
    } finally {
      setSaving(false);
    }
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: "Student Name",
      cell: (row) => `${row.firstname} ${row.lastname}`,
    },
    {
      name: "Present",
      cell: (row) => (
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            name={`attendance-${row._id}`}
            id={`present-${row._id}`}
            className="hidden peer"
            checked={attendance[row._id] === 1}
            onChange={() => handleAttendanceChange(row._id, 1)}
          />
          <label htmlFor={`present-${row._id}`} className="cursor-pointer p-1 bg-gray-300 rounded-full">
            <img
              src={
                attendance[row._id] === 1
                  ? Icons.studentPresent
                  : Icons.studentPending
              }
              alt="Present Icon"
            />
          </label>
        </div>
      ),
    },
    {
      name: "Absent",
      cell: (row) => (
        <div className="flex items-center space-x-4">
          <input
            type="radio"
            name={`attendance-${row._id}`}
            id={`absent-${row._id}`}
            className="hidden peer"
            checked={attendance[row._id] === 2}
            onChange={() => handleAttendanceChange(row._id, 2)}
          />
          <label htmlFor={`absent-${row._id}`} className="cursor-pointer p-1 bg-gray-300 rounded-full">
            <img
              src={
                attendance[row._id] === 2
                  ? Icons.studentAbsent
                  : Icons.studentPending
              }
              alt="Absent Icon"
            />
          </label>
        </div>
      ),
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Class",
      selector: (row) => row.class_info?.class_name,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          to={{ pathname: `/t/attendance/view/${row._id}`, state: { student: row } }}
          className="font-medium text-[#00509D] hover:underline"
        >
          View
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  // Filter students by search text
  const filteredData = students?.filter((item) =>
    item.firstname?.toLowerCase().includes(filterText.toLowerCase()) ||
    item.lastname?.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="px-3 md:px-10 pt-10 md:pt-5 mt-10">
      <BackButton history={history} />
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Header category="Attendance" title="Mark Student Attendance" />
      </div>

      <div className="p-4 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
        <div className="flex flex-wrap justify-between items-center gap-4 mb-4">
          {/* Date Input */}
          <div className="relative w-full sm:w-auto">
            <input
              type="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              className="w-full sm:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block p-2.5"
              placeholder="Select Date"
            />
          </div>
          {/* Search and Save Attendance Section */}
          <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto gap-4">
            {/* Search Input */}
            <form className="w-full md:w-auto">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                  <MdSearch />
                </div>
                <input
                  type="text"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="w-full sm:w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm sm:text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block pl-8 p-2.5"
                  placeholder="Search"
                />
              </div>
            </form>
            {/* Save Attendance Button */}
            <button
              type="button"
              onClick={saveAttendance}
              className="w-full sm:w-auto py-2 px-6 border border-transparent shadow-sm text-sm sm:text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
              disabled={saving}
            >
              {saving ? "Saving..." : "Save Attendance"}
            </button>
          </div>
        </div>

        <div className="border rounded-3xl">
          <DataTable
            data={filteredData}
            columns={columns}
            getRowId={(row) => row._id}
            keyField="_id"
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
            paginationComponent={CustomPagination}
            paginationRowsPerPageOptions={[10, 20, 30, 50]}
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
      {error && <AlertNotification type="error" message={error} onClose={() => setError("")} />}
      {success && <AlertNotification type="success" message={success} onClose={() => setSuccess("")} />}
    </div>
  );
};

export default StudentAttendance;
