import React, { useState } from "react";
import AddStudentModal from "./AddStudentModal";
import { FaCloudUploadAlt } from "react-icons/fa";
import { userRequest } from "../../utils/requestMethods";

const AddStudentDetailsModal = ({ closeModal, onStudentCreated, schoolCode, schoolId, classId }) => {
  const [screen, setScreen] = useState("main");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Handle navigation between screens
  const navigateTo = (targetScreen) => {
    setScreen(targetScreen);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/templates/student-upload-template.csv";
    link.setAttribute("download", "student-upload-template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!file) {
      setError("Please provide a file!");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      let studentData = new FormData();
      studentData.append("file", file);
      studentData.append("school_code", schoolCode);
      studentData.append("class_id", classId);

      await userRequest.post("/children/upload", studentData );
      setSuccess(true);
      onStudentCreated();
      closeModal();
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl w-full max-w-md p-6">
        {/* Close Button */}
        <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Add Student Detail</h2>
            <button type="button" onClick={closeModal} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
        </div>

        {/* Main Screen */}
        {screen === "main" && (
          <div className="mt-4">
            <p className="text-sm text-gray-500">
              Set up your academic terms start and end dates
            </p>

            {/* Manual Upload Button */}
            <button onClick={() => navigateTo("manualUpload")} className="mt-4 w-full flex items-center justify-between bg-gray-50 border border-gray-200 rounded-lg px-4 py-3 hover:bg-gray-100">
                <div>
                    <h3 className="text-sm font-bold">Manual Upload</h3>
                    <p className="text-sm text-gray-500">
                        Manually create profiles for your students
                    </p>
                </div>
                <span className="text-gray-400 text-lg">&rarr;</span>
            </button>

            {/* Bulk Uploads Button */}
            <button onClick={() => navigateTo("bulkUploads")} className="mt-4 w-full flex items-center justify-between bg-gray-50 border border-gray-200 rounded-lg px-4 py-3 hover:bg-gray-100">
                <div>
                    <h3 className="text-sm font-bold">Bulk Uploads</h3>
                    <p className="text-sm text-gray-500">
                        Upload students' profile in bulk as CSV or Excel file
                    </p>
                </div>
                <span className="text-gray-400">&rarr;</span>
            </button>
          </div>
        )}

        {/* Manual Upload Screen */}
        {screen === "manualUpload" && (
            <AddStudentModal 
                goback={() => navigateTo("main")}
                closeModal={closeModal}
                onStudentCreated={onStudentCreated}
                schoolId={schoolId}
                schoolCode={schoolCode}
                classId={classId}
            />
        )}
        {/* Bulk Uploads Screen */}
        {screen === "bulkUploads" && (
        <div className="mt-4">
            {error &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
                <span className="font-medium">Error!</span> {error}
            </div>}
            {success &&
            <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
                <span className="font-medium">Success!</span> {success}
            </div>}
            <p className="text-sm text-gray-500 mt-2">
              To upload a list of student for this class you have to <span className="text-[#3F3DA5] cursor-pointer" onClick={handleDownload}>Download template</span> and then populate it and them come back here to upload it, please make sure you follow the <span className="text-[#3F3DA5] cursor-pointer" onClick={handleDownload}>Downloaded template</span>
            </p>
            <form className="space-y-6" onSubmit={uploadHandler}>
                <div className="mt-6">
                    <label className="block text-gray-700 text-sm font-medium mt-6 mb-2">
                        ATTACH DOCUMENTS
                    </label>
                    <label htmlFor="file-upload" className="block border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer">
                        <FaCloudUploadAlt className="text-gray-500 text-4xl mx-auto" />
                        <p className="text-gray-700 mt-4"><span className="text-[#3F3DA5]">Click to upload</span> or drag and drop</p>
                        <p className="text-gray-500">.CSV</p>
                        <input
                            id="file-upload"
                            type="file"
                            onChange={handleFileChange}
                            className="hidden"
                            accept=".csv"
                        />
                    </label>
                    {file && (
                    <div className="mt-4 text-sm text-gray-700">
                        <p><strong>Selected file:</strong> {file.name}</p>
                        <p><strong>Size:</strong> {(file.size / 1024).toFixed(2)} KB</p>
                    </div>
                    )}
                    <p className="text-gray-500 text-xs mt-2">
                        Accepted File Type: .csv
                    </p>
                </div>
                <div className="flex justify-between items-center">
                    <button type="button" onClick={() => navigateTo("main")} className="text-gray-600 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-semibold rounded-full text-sm px-5 py-2 mr-3">
                        &larr; Back
                    </button>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5" disabled={loading}>
                        {loading ? "Uploading..." : "Upload Document"}
                    </button>
                </div>
            </form>
        </div>
        )}
      </div>
    </div>
  );
};

export default AddStudentDetailsModal;
